import React from 'react'
import { CFSelect, CFView, DefaultScreen, MobileScreen } from 'components'
import { isEmpty } from 'lodash'
import { moment } from 'cf-utils'
import { dispatch } from 'store'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView h3 futura bold color="#B3BF54" p="15px" column center>
          BUSINESS HOURS
        </CFView>
        <CFView maxWidth="250px">
          <CFSelect selector={dispatch.restaurant.getHours}>
            {hours =>
              [1, 2, 3, 4, 5, 6, 0].map(day => (
                <CFView key={day} column>
                  <CFView row w="100%" mb="10px">
                    <CFView w="80px">
                      <CFView h5 black bold>{`${moment()
                        .weekday(day)
                        .format('ddd')}`}</CFView>
                    </CFView>
                    <CFView h5 black bold w="100%" row justifyEnd textRight>
                      {isEmpty(hours)
                        ? ''
                        : hours[day].open && hours[day].close
                        ? `${moment(hours[day].open).format('LT')} ${
                            hours[day].breakStart && hours[day].breakEnd
                              ? ` - ${moment(hours[day].breakStart).format(
                                  'LT'
                                )} ${moment(hours[day].breakEnd).format(
                                  'LT'
                                )} -`
                              : '-'
                          } ${moment(hours[day].close).format('LT')}`
                        : 'Closed'}
                    </CFView>
                  </CFView>
                </CFView>
              ))
            }
          </CFSelect>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView h3 futura bold color="#B3BF54" pb="20px">
          BUSINESS HOURS
        </CFView>
        <CFView minWidth="300px">
          <CFSelect selector={dispatch.restaurant.getHours}>
            {hours =>
              [1, 2, 3, 4, 5, 6, 0].map(day => (
                <CFView key={day}>
                  <CFView row w="100%" mb="10px">
                    <CFView w="80px">
                      <CFView h5 black bold>{`${moment()
                        .weekday(day)
                        .format('ddd')}`}</CFView>
                    </CFView>
                    <CFView h5 black bold>
                      {isEmpty(hours)
                        ? ''
                        : hours[day].open && hours[day].close
                        ? `${moment(hours[day].open).format('LT')} ${
                            hours[day].breakStart && hours[day].breakEnd
                              ? ` - ${moment(hours[day].breakStart).format(
                                  'LT'
                                )}, ${moment(hours[day].breakEnd).format(
                                  'LT'
                                )} -`
                              : '-'
                          } ${moment(hours[day].close).format('LT')}`
                        : 'Closed'}
                    </CFView>
                  </CFView>
                </CFView>
              ))
            }
          </CFSelect>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
