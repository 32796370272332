import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, logoTitle, viewMenu, sushiCoMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/8GFwb8UTNOQ2rbtqc9kk/locations/Y7GXtSFgqD6z5v9Jpd8q'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView
          column
          center
          w="100%"
          bg="#B3BF54"
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
        >
          <CFImage
            ml="25px"
            mv="5px"
            h="45px"
            src={logo}
            alt="Joy SushiLogo Title"
          />
          {/* <CFView hover mt="5px" mb="-5px">
            <CFLink href={sushiCoMenu} newTab>
              <CFImage h="60px" src={viewMenu} alt="Joy SushiView Menu Button" />
            </CFLink>
          </CFView> */}
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          column
          center
          w="100%"
          h="60px"
          bg="#B3BF54"
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
          zIndex={98}
        >
          <CFView row justifyBetween alignCenter w="100%" maxWidth="1400px">
            <CFView row center>
              <CFImage
                h="50px"
                ml="65px"
                src={logo}
                alt="Joy SushiKorean Logo Title"
              />
            </CFView>
            {/* <CFView hover mt="10px" mr="25px">
              <CFLink href={sushiCoMenu} newTab>
                <CFImage
                  h="65px"
                  src={viewMenu}
                  alt="Joy SushiView Menu Button"
                />
              </CFLink>
            </CFView> */}
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
